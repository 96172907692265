import "./App.css";
import "./Components/style.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import BrowserRouterApp from "./Components/BrowserRouterApp.js";
function App() {
  return (
    <>
      <BrowserRouterApp />
    </>
  );
}

export default App;
