// GALLERY
import gallery1 from "../assests/img/GelleryImg/coastal-places-and-things/Folly Beach, 2024, acrylic & oil on wood panel.webp";
import gallery2 from "../assests/img/GelleryImg/Nature-Humans-Animals-and Elements/Lost in Reading, 2022-23, acrylic & oil on wood panel.webp";
import gallery3 from "../assests/img/GelleryImg/Playgrounds/Shrine, 2002, enamel & oil on wood panel.webp";
import gallery4 from "../assests/img/GelleryImg/Interiors-and-Still Lifes/Green Chair 1987 oil on canvas.webp";
import gallery5 from "../assests/img/GelleryImg/Limitations-and-Death/Nature Mort Series  Mourning, diptych,  2022, acrylic, oil, metallic enamel & on two wood panels.webp";

// CoastalPlacesandThings
import CoastalPlacesandThings1 from "../assests/img/GelleryImg/coastal-places-and-things/Folly Beach, 2024, acrylic & oil on wood panel.webp";
import CoastalPlacesandThings2 from "../assests/img/GelleryImg/coastal-places-and-things/The Decampment, 2024, acrylic & oil on wood panel.webp";
import CoastalPlacesandThings3 from "../assests/img/GelleryImg/coastal-places-and-things/Kayaks at Rest,  2024, acrylic & oil on wood panel.webp";
import CoastalPlacesandThings4 from "../assests/img/GelleryImg/coastal-places-and-things/Beach Paraphernalia 3,  2023, acrylic & oil on wood panel.webp";
import CoastalPlacesandThings5 from "../assests/img/GelleryImg/coastal-places-and-things/Birds in Repose 200.webp";
import CoastalPlacesandThings6 from "../assests/img/GelleryImg/coastal-places-and-things/Pole, Guy lines, and Sandbag Stake, 2021, acrylic & oil on wood panel.webp";
import CoastalPlacesandThings7 from "../assests/img/GelleryImg/coastal-places-and-things/Beach Paraphernalia 2, 2021, acrylic & oil on wood panel.webp";
import CoastalPlacesandThings71 from "../assests/img/GelleryImg/coastal-places-and-things/Beach Paraphernalia 4, 2024, Acrylic and Oil on Wood Panel.webp";
import CoastalPlacesandThings8 from "../assests/img/GelleryImg/coastal-places-and-things/Beach Paraphernalia 1, 2021, acrylic & oil on wood panel.webp";
import CoastalPlacesandThings9 from "../assests/img/GelleryImg/coastal-places-and-things/Beach Forms 2,  2023, acrylic & oil on wood panel.webp";
import CoastalPlacesandThings10 from "../assests/img/GelleryImg/coastal-places-and-things/Walt and the Seagull 1996 acrylic and oil on prepared museum board.webp";
import CoastalPlacesandThings11 from "../assests/img/GelleryImg/coastal-places-and-things/Beach Shapes 1, 2022, acrylic & oil on wood panel.webp";
import CoastalPlacesandThings12 from "../assests/img/GelleryImg/coastal-places-and-things/Beach Shapes 2, 2023, acrylic & oil on wood panel.webp";
import CoastalPlacesandThings13 from "../assests/img/GelleryImg/coastal-places-and-things/Lake Michigan Lifeguard Tower 1997 acrylic and oil.webp";
import CoastalPlacesandThings14 from "../assests/img/GelleryImg/coastal-places-and-things/Shells in Marsh, 2011, acrylic & oil on wood panel.webp";
import CoastalPlacesandThings15 from "../assests/img/GelleryImg/coastal-places-and-things/Walt on the Beach 1996 acrylic and oil on prepared museum board.webp";
import CoastalPlacesandThings16 from "../assests/img/GelleryImg/coastal-places-and-things/Bather on Lake Michigan Beach Wall 1996 oil on prepared museum board.webp";
import CoastalPlacesandThings17 from "../assests/img/GelleryImg/coastal-places-and-things/Beach Shapes 3, 2023, acrylic & oil on wood panel.webp";
import CoastalPlacesandThings18 from "../assests/img/GelleryImg/coastal-places-and-things/Boone Brothers Recreating Seventh and Final Day on Folly Island, 2024, Acrylic and Oll on Wood Panel.webp";

// CoastalPlacesandThingsThumbnail
import CoastalPlacesandThingsThumbnail1 from "../assests/img/Thumbanil/coastal-places-and-things/Folly Beach, 2024, acrylic & oil on wood panel.webp";
import CoastalPlacesandThingsThumbnail2 from "../assests/img/Thumbanil/coastal-places-and-things/The Decampment, 2024, acrylic & oil on wood panel.webp";
import CoastalPlacesandThingsThumbnail3 from "../assests/img/Thumbanil/coastal-places-and-things/Kayaks at Rest,  2024, acrylic & oil on wood panel.webp";
import CoastalPlacesandThingsThumbnail4 from "../assests/img/Thumbanil/coastal-places-and-things/Beach Paraphernalia 3,  2023, acrylic & oil on wood panel.webp";
import CoastalPlacesandThingsThumbnail5 from "../assests/img/Thumbanil/coastal-places-and-things/Birds in Repose 200.webp";
import CoastalPlacesandThingsThumbnail6 from "../assests/img/Thumbanil/coastal-places-and-things/Pole, Guy lines, and Sandbag Stake, 2021, acrylic & oil on wood panel.webp";
import CoastalPlacesandThingsThumbnail7 from "../assests/img/Thumbanil/coastal-places-and-things/Beach Paraphernalia 2, 2021, acrylic & oil on wood panel.webp";
import CoastalPlacesandThingsThumbnail71 from "../assests/img/Thumbanil/coastal-places-and-things/Beach Paraphernalia 4, 2024, Acrylic and Oil on Wood Panel.webp";
import CoastalPlacesandThingsThumbnail8 from "../assests/img/Thumbanil/coastal-places-and-things/Beach Paraphernalia 1, 2021, acrylic & oil on wood panel.webp";
import CoastalPlacesandThingsThumbnail9 from "../assests/img/Thumbanil/coastal-places-and-things/Beach Forms 2,  2023, acrylic & oil on wood panel.webp";
import CoastalPlacesandThingsThumbnail10 from "../assests/img/Thumbanil/coastal-places-and-things/Walt and the Seagull 1996 acrylic and oil on prepared museum board.webp";
import CoastalPlacesandThingsThumbnail11 from "../assests/img/Thumbanil/coastal-places-and-things/Beach Shapes 1, 2022, acrylic & oil on wood panel.webp";
import CoastalPlacesandThingsThumbnail12 from "../assests/img/Thumbanil/coastal-places-and-things/Beach Shapes 2, 2023, acrylic & oil on wood panel.webp";
import CoastalPlacesandThingsThumbnail13 from "../assests/img/Thumbanil/coastal-places-and-things/Lake Michigan Lifeguard Tower 1997 acrylic and oil.webp";
import CoastalPlacesandThingsThumbnail14 from "../assests/img/Thumbanil/coastal-places-and-things/Shells in Marsh, 2011, acrylic & oil on wood panel.webp";
import CoastalPlacesandThingsThumbnail15 from "../assests/img/Thumbanil/coastal-places-and-things/Walt on the Beach 1996 acrylic and oil on prepared museum board.webp";
import CoastalPlacesandThingsThumbnail16 from "../assests/img/Thumbanil/coastal-places-and-things/Bather on Lake Michigan Beach Wall 1996 oil on prepared museum board.webp";
import CoastalPlacesandThingsThumbnail17 from "../assests/img/Thumbanil/coastal-places-and-things/Beach Shapes 3, 2023, acrylic & oil on wood panel.webp";
import CoastalPlacesandThingsThumbnail18 from "../assests/img/Thumbanil/coastal-places-and-things/Boone Brothers Recreating Seventh and Final Day on Folly Island, 2024, Acrylic and Oll on Wood Panel-thumbnail.webp";

// HumansAndAnimals
import HumansAndAnimals1 from "../assests/img/GelleryImg/Nature-Humans-Animals-and Elements/Lost in Reading, 2022-23, acrylic & oil on wood panel.webp";
import HumansAndAnimals2 from "../assests/img/GelleryImg/Nature-Humans-Animals-and Elements/Bear Market, 2014-15, acrylic, oil, newsprint & acrylic sealant on two wood panels.webp";
import HumansAndAnimals3 from "../assests/img/GelleryImg/Nature-Humans-Animals-and Elements/Meditation on a Pocket, triptych, 2012, acrylic & oil on three wood panels with maple frame.webp";
import HumansAndAnimals4 from "../assests/img/GelleryImg/Nature-Humans-Animals-and Elements/Waiting, 2012, acrylic, oil & cold wax on two wood panels.webp";
import HumansAndAnimals5 from "../assests/img/GelleryImg/Nature-Humans-Animals-and Elements/Worker Series The Sweeper, Atlanta, 2011, spray paint, acrylic, oil & cold wax on wood panel.webp";
import HumansAndAnimals6 from "../assests/img/GelleryImg/Nature-Humans-Animals-and Elements/Awaiting Walt I 1997 acrylic and oil on prepared museum board.webp";
import HumansAndAnimals7 from "../assests/img/GelleryImg/Nature-Humans-Animals-and Elements/Awaiting Walt II 1997 acrylic and oil on prepared museum board.webp";
import HumansAndAnimals8 from "../assests/img/GelleryImg/Nature-Humans-Animals-and Elements/Let Sleeping Dogs Lie, 2013, acrylic, oil & metallic enamel on wood panel.webp";
import HumansAndAnimals9 from "../assests/img/GelleryImg/Nature-Humans-Animals-and Elements/Blue Canine, 2012, acrylic & oil on wood panel.webp";
import HumansAndAnimals10 from "../assests/img/GelleryImg/Nature-Humans-Animals-and Elements/Encounter, Sniff & Play, 2012, acrylic & oil on wood panel.webp";
import HumansAndAnimals11 from "../assests/img/GelleryImg/Nature-Humans-Animals-and Elements/The Departure, 2012, acrylic, oil & metallic enamel on wood panel.webp";
import HumansAndAnimals12 from "../assests/img/GelleryImg/Nature-Humans-Animals-and Elements/At the Park, 2012, enamel, oil & cold wax on wood panel.webp";
import HumansAndAnimals13 from "../assests/img/GelleryImg/Nature-Humans-Animals-and Elements/Things that Fly, 2011, acrylic, oil & cold wax on wood panel.webp";
import HumansAndAnimals14 from "../assests/img/GelleryImg/Nature-Humans-Animals-and Elements/Nature Mort Series  Dead Sea Lion, California, Daytime, 2016,  acrylic, oil & cold wax on wood panel.webp";
import HumansAndAnimals15 from "../assests/img/GelleryImg/Nature-Humans-Animals-and Elements/Nature Mort Series  Dead Sea Lion, California, Twilight, 2016, acrylic & oil on wood panel.webp";
import HumansAndAnimals16 from "../assests/img/GelleryImg/Nature-Humans-Animals-and Elements/Nature Mort Series  Dead Sea Lion, California, Nighttime, 2017, acrylic & oil on wood panel.webp";
import HumansAndAnimals17 from "../assests/img/GelleryImg/Nature-Humans-Animals-and Elements/Nature Mort Series  Dead Goose, 2019,  acrylic & oil on museum board,.webp";
import HumansAndAnimals18 from "../assests/img/GelleryImg/Nature-Humans-Animals-and Elements/Nature Mort Series  Severed Leg, 2017, acrylic & oil on museum board.webp";
import HumansAndAnimals19 from "../assests/img/GelleryImg/Nature-Humans-Animals-and Elements/Pastoral Eclipse – AM 1998 acrylic and oil on four conjoined canvases.webp";
import HumansAndAnimals20 from "../assests/img/GelleryImg/Nature-Humans-Animals-and Elements/Pastoral Eclipse – PM 1998-99 acrylic and oil on four conjoined.webp";
import HumansAndAnimals21 from "../assests/img/GelleryImg/Nature-Humans-Animals-and Elements/Entropy Series  Rotting Stumps, 2022 ,  acrylic & oil on wood panel.webp";
import HumansAndAnimals22 from "../assests/img/GelleryImg/Nature-Humans-Animals-and Elements/Entropy Series  Double Negative 1, 2016, acrylic & oil on wood panel.webp";
import HumansAndAnimals23 from "../assests/img/GelleryImg/Nature-Humans-Animals-and Elements/Entropy Series  Double Negative 2, 2016, acrylic & oil on wood panel.webp";
import HumansAndAnimals24 from "../assests/img/GelleryImg/Nature-Humans-Animals-and Elements/Entropy Series  Double Negative 3, 2016, acrylic & oil on museum board.webp";
import HumansAndAnimals25 from "../assests/img/GelleryImg/Nature-Humans-Animals-and Elements/Appalachian Rocks, 2011, acrylic, & oil on wood panel.webp";
import HumansAndAnimals26 from "../assests/img/GelleryImg/Nature-Humans-Animals-and Elements/Atlanta Lilies, 2013, acrylic & oil on wood panel.webp";
import HumansAndAnimals27 from "../assests/img/GelleryImg/Nature-Humans-Animals-and Elements/Atlanta Builds Series , 2023,  acrylic & oil on wood panel.webp";

// HumansAndAnimalsThumbanil
import HumansAndAnimalsThumbnail1 from "../assests/img/Thumbanil/Nature-Humans-Animals-and Elements/Lost in Reading, 2022-23, acrylic & oil on wood panel.webp";
import HumansAndAnimalsThumbnail2 from "../assests/img/Thumbanil/Nature-Humans-Animals-and Elements/Bear Market, 2014-15, acrylic, oil, newsprint & acrylic sealant on two wood panels.webp";
import HumansAndAnimalsThumbnail3 from "../assests/img/Thumbanil/Nature-Humans-Animals-and Elements/Meditation on a Pocket, triptych, 2012, acrylic & oil on three wood panels with maple frame.webp";
import HumansAndAnimalsThumbnail4 from "../assests/img/Thumbanil/Nature-Humans-Animals-and Elements/Waiting, 2012, acrylic, oil & cold wax on two wood panels.webp";
import HumansAndAnimalsThumbnail5 from "../assests/img/Thumbanil/Nature-Humans-Animals-and Elements/Worker Series The Sweeper, Atlanta, 2011, spray paint, acrylic, oil & cold wax on wood panel.webp";
import HumansAndAnimalsThumbnail6 from "../assests/img/Thumbanil/Nature-Humans-Animals-and Elements/Awaiting Walt I 1997 acrylic and oil on prepared museum board.webp";
import HumansAndAnimalsThumbnail7 from "../assests/img/Thumbanil/Nature-Humans-Animals-and Elements/Awaiting Walt II 1997 acrylic and oil on prepared museum board.webp";
import HumansAndAnimalsThumbnail8 from "../assests/img/Thumbanil/Nature-Humans-Animals-and Elements/Let Sleeping Dogs Lie, 2013, acrylic, oil & metallic enamel on wood panel.webp";
import HumansAndAnimalsThumbnail9 from "../assests/img/Thumbanil/Nature-Humans-Animals-and Elements/Blue Canine, 2012, acrylic & oil on wood panel.webp";
import HumansAndAnimalsThumbnail10 from "../assests/img/Thumbanil/Nature-Humans-Animals-and Elements/Encounter, Sniff & Play, 2012, acrylic & oil on wood panel.webp";
import HumansAndAnimalsThumbnail11 from "../assests/img/Thumbanil/Nature-Humans-Animals-and Elements/The Departure, 2012, acrylic, oil & metallic enamel on wood panel.webp";
import HumansAndAnimalsThumbnail12 from "../assests/img/Thumbanil/Nature-Humans-Animals-and Elements/At the Park, 2012, enamel, oil & cold wax on wood panel.webp";
import HumansAndAnimalsThumbnail13 from "../assests/img/Thumbanil/Nature-Humans-Animals-and Elements/Things that Fly, 2011, acrylic, oil & cold wax on wood panel.webp";
import HumansAndAnimalsThumbnail14 from "../assests/img/Thumbanil/Nature-Humans-Animals-and Elements/Nature Mort Series  Dead Sea Lion, California, Daytime, 2016,  acrylic, oil & cold wax on wood panel.webp";
import HumansAndAnimalsThumbnail15 from "../assests/img/Thumbanil/Nature-Humans-Animals-and Elements/Nature Mort Series  Dead Sea Lion, California, Twilight, 2016, acrylic & oil on wood panel.webp";
import HumansAndAnimalsThumbnail16 from "../assests/img/Thumbanil/Nature-Humans-Animals-and Elements/Nature Mort Series  Dead Sea Lion, California, Nighttime, 2017, acrylic & oil on wood panel.webp";
import HumansAndAnimalsThumbnail17 from "../assests/img/Thumbanil/Nature-Humans-Animals-and Elements/Nature Mort Series  Dead Goose, 2019,  acrylic & oil on museum board,.webp";
import HumansAndAnimalsThumbnail18 from "../assests/img/Thumbanil/Nature-Humans-Animals-and Elements/Nature Mort Series  Severed Leg, 2017, acrylic & oil on museum board.webp";
import HumansAndAnimalsThumbnail19 from "../assests/img/Thumbanil/Nature-Humans-Animals-and Elements/Pastoral Eclipse – AM 1998 acrylic and oil on four conjoined canvases.webp";
import HumansAndAnimalsThumbnail20 from "../assests/img/Thumbanil/Nature-Humans-Animals-and Elements/Pastoral Eclipse – PM 1998-99 acrylic and oil on four conjoined.webp";
import HumansAndAnimalsThumbnail21 from "../assests/img/Thumbanil/Nature-Humans-Animals-and Elements/Entropy Series  Rotting Stumps, 2022 ,  acrylic & oil on wood panel.webp";
import HumansAndAnimalsThumbnail22 from "../assests/img/Thumbanil/Nature-Humans-Animals-and Elements/Entropy Series  Double Negative 1, 2016, acrylic & oil on wood panel.webp";
import HumansAndAnimalsThumbnail23 from "../assests/img/Thumbanil/Nature-Humans-Animals-and Elements/Entropy Series  Double Negative 2, 2016, acrylic & oil on wood panel.webp";
import HumansAndAnimalsThumbnail24 from "../assests/img/Thumbanil/Nature-Humans-Animals-and Elements/Entropy Series  Double Negative 3, 2016, acrylic & oil on museum board.webp";
import HumansAndAnimalsThumbnail25 from "../assests/img/Thumbanil/Nature-Humans-Animals-and Elements/Appalachian Rocks, 2011, acrylic, & oil on wood panel.webp";
import HumansAndAnimalsThumbnail26 from "../assests/img/Thumbanil/Nature-Humans-Animals-and Elements/Atlanta Lilies, 2013, acrylic & oil on wood panel.webp";
import HumansAndAnimalsThumbnail27 from "../assests/img/Thumbanil/Nature-Humans-Animals-and Elements/Atlanta Builds Series , 2023,  acrylic & oil on wood panel.webp";

// Playgrounds
import Playgrounds1 from "../assests/img/GelleryImg/Playgrounds/Shrine, 2002, enamel & oil on wood panel.webp";
import Playgrounds2 from "../assests/img/GelleryImg/Playgrounds/Spiral Slide, 2002, enamel & oil on wood panel.webp";
import Playgrounds3 from "../assests/img/GelleryImg/Playgrounds/Seesaw, 2002, enamel & oil on wood panel.webp";
import Playgrounds4 from "../assests/img/GelleryImg/Playgrounds/Swing Series Dusk, 2001, enamel & oil on wood panel.webp";
import Playgrounds5 from "../assests/img/GelleryImg/Playgrounds/Swing Series  Red Sky, 2001, oil on canvas.webp";
import Playgrounds6 from "../assests/img/GelleryImg/Playgrounds/Swing Series  Evening, 2000, oil on two canvases.webp";
import Playgrounds7 from "../assests/img/GelleryImg/Playgrounds/Slide Twilight, 2001 oil on two canvases.webp";
import Playgrounds8 from "../assests/img/GelleryImg/Playgrounds/Slide Twilight 2001, acrylic, oil & cold wax on wood panel.webp";
import Playgrounds9 from "../assests/img/GelleryImg/Playgrounds/Swing Series 1997 oil on prepared museum board.webp";

// PlaygroundsThumbnail
import PlaygroundsThumbnail1 from "../assests/img/Thumbanil/Playgrounds/Shrine, 2002, enamel & oil on wood panel.webp";
import PlaygroundsThumbnail2 from "../assests/img/Thumbanil/Playgrounds/Spiral Slide, 2002, enamel & oil on wood panel.webp";
import PlaygroundsThumbnail3 from "../assests/img/Thumbanil/Playgrounds/Seesaw, 2002, enamel & oil on wood panel.webp";
import PlaygroundsThumbnail4 from "../assests/img/Thumbanil/Playgrounds/Swing Series Dusk, 2001, enamel & oil on wood panel.webp";
import PlaygroundsThumbnail5 from "../assests/img/Thumbanil/Playgrounds/Swing Series  Red Sky, 2001, oil on canvas.webp";
import PlaygroundsThumbnail6 from "../assests/img/Thumbanil/Playgrounds/Swing Series  Evening, 2000, oil on two canvases.webp";
import PlaygroundsThumbnail7 from "../assests/img/Thumbanil/Playgrounds/Slide Twilight, 2001 oil on two canvases.webp";
import PlaygroundsThumbnail8 from "../assests/img/Thumbanil/Playgrounds/Slide Twilight 2001, acrylic, oil & cold wax on wood panel.webp";
import PlaygroundsThumbnail9 from "../assests/img/Thumbanil/Playgrounds/Swing Series 1997 oil on prepared museum board.webp";

// Interiors-and-Still Lifes
import InteriorsAndStillLifes1 from "../assests/img/GelleryImg/Interiors-and-Still Lifes/Green Chair 1987 oil on canvas.webp";
import InteriorsAndStillLifes2 from "../assests/img/GelleryImg/Interiors-and-Still Lifes/Violet in Arch 1992 oil on prepared museum board.webp";
import InteriorsAndStillLifes3 from "../assests/img/GelleryImg/Interiors-and-Still Lifes/Violet Above on Block 1992 oil.webp";
import InteriorsAndStillLifes4 from "../assests/img/GelleryImg/Interiors-and-Still Lifes/Violets of Milkcrates 1990 oil.webp";
import InteriorsAndStillLifes5 from "../assests/img/GelleryImg/Interiors-and-Still Lifes/Eclipse Series Two African Violets 1990 oil on canvas.webp";
import InteriorsAndStillLifes6 from "../assests/img/GelleryImg/Interiors-and-Still Lifes/Corner of My Studio at Night 1987 oil.webp";
import InteriorsAndStillLifes7 from "../assests/img/GelleryImg/Interiors-and-Still Lifes/His and Hers 1988 pastel and colored pencil.webp";
import InteriorsAndStillLifes8 from "../assests/img/GelleryImg/Interiors-and-Still Lifes/Telephone Series Solitude 1988 oil on prepared museum board.webp";
import InteriorsAndStillLifes9 from "../assests/img/GelleryImg/Interiors-and-Still Lifes/Telephone Series Phone Intimacy 1988 oil on prepared museum.webp";
import InteriorsAndStillLifes10 from "../assests/img/GelleryImg/Interiors-and-Still Lifes/Ashtray 1987 oil on prepared museum board.webp";
import InteriorsAndStillLifes11 from "../assests/img/GelleryImg/Interiors-and-Still Lifes/Concrete Block 1998 oil on prepared museum board.webp";
import InteriorsAndStillLifes12 from "../assests/img/GelleryImg/Interiors-and-Still Lifes/Cupcakes on a Speaker, Rearview 1986 oil.webp";
import InteriorsAndStillLifes13 from "../assests/img/GelleryImg/Interiors-and-Still Lifes/Tools and Cupcakes 1987 oil.webp";
import InteriorsAndStillLifes14 from "../assests/img/GelleryImg/Interiors-and-Still Lifes/Chair and Coffee Table with Ashtray and Book 1987 oil.webp";
import InteriorsAndStillLifes15 from "../assests/img/GelleryImg/Interiors-and-Still Lifes/Cupcakes and Hammer 1986 oil on prepared museum board.webp";
import InteriorsAndStillLifes16 from "../assests/img/GelleryImg/Interiors-and-Still Lifes/Eclipse Series Bottles on a Board and Block 1992 oil.webp";
import InteriorsAndStillLifes17 from "../assests/img/GelleryImg/Interiors-and-Still Lifes/Bottles on a Table 1985 oil on prepared museum board.webp";
import InteriorsAndStillLifes18 from "../assests/img/GelleryImg/Interiors-and-Still Lifes/Eclipse Series Bottles Prone on a Speaker 1989.webp";
import InteriorsAndStillLifes19 from "../assests/img/GelleryImg/Interiors-and-Still Lifes/Rhythmic Eclipse 1983 oil.webp";
import InteriorsAndStillLifes20 from "../assests/img/GelleryImg/Interiors-and-Still Lifes/Eclipse Series Bottles on a Speaker II 1988 oil on prepared museum board.webp";
import InteriorsAndStillLifes21 from "../assests/img/GelleryImg/Interiors-and-Still Lifes/Plants on a Coffee Table 1982 oil on prepared museum board.webp";
import InteriorsAndStillLifes22 from "../assests/img/GelleryImg/Interiors-and-Still Lifes/Fallen Sprayer 1983 oil.webp";
import InteriorsAndStillLifes23 from "../assests/img/GelleryImg/Interiors-and-Still Lifes/Sprayers on Parade 1983 oil on canvas.webp";
import InteriorsAndStillLifes24 from "../assests/img/GelleryImg/Interiors-and-Still Lifes/The Three Graces 1983 oil.webp";

// Interiors-and-Still LifesThumbnail
import InteriorsAndStillLifesThumbnail1 from "../assests/img/Thumbanil/Interiors-and-Still Lifes/Green Chair 1987 oil on canvas.webp";
import InteriorsAndStillLifesThumbnail2 from "../assests/img/Thumbanil/Interiors-and-Still Lifes/Violet in Arch 1992 oil on prepared museum board.webp";
import InteriorsAndStillLifesThumbnail3 from "../assests/img/Thumbanil/Interiors-and-Still Lifes/Violet Above on Block 1992 oil.webp";
import InteriorsAndStillLifesThumbnail4 from "../assests/img/Thumbanil/Interiors-and-Still Lifes/Violets of Milkcrates 1990 oil.webp";
import InteriorsAndStillLifesThumbnail5 from "../assests/img/Thumbanil/Interiors-and-Still Lifes/Eclipse Series Two African Violets 1990 oil on canvas.webp";
import InteriorsAndStillLifesThumbnail6 from "../assests/img/Thumbanil/Interiors-and-Still Lifes/Corner of My Studio at Night 1987 oil.webp";
import InteriorsAndStillLifesThumbnail7 from "../assests/img/Thumbanil/Interiors-and-Still Lifes/His and Hers 1988 pastel and colored pencil.webp";
import InteriorsAndStillLifesThumbnail8 from "../assests/img/Thumbanil/Interiors-and-Still Lifes/Telephone Series Solitude 1988 oil on prepared museum board.webp";
import InteriorsAndStillLifesThumbnail9 from "../assests/img/Thumbanil/Interiors-and-Still Lifes/Telephone Series Phone Intimacy 1988 oil on prepared museum.webp";
import InteriorsAndStillLifesThumbnail10 from "../assests/img/Thumbanil/Interiors-and-Still Lifes/Ashtray 1987 oil on prepared museum board.webp";
import InteriorsAndStillLifesThumbnail11 from "../assests/img/Thumbanil/Interiors-and-Still Lifes/Concrete Block 1998 oil on prepared museum board.webp";
import InteriorsAndStillLifesThumbnail12 from "../assests/img/Thumbanil/Interiors-and-Still Lifes/Cupcakes on a Speaker, Rearview 1986 oil.webp";
import InteriorsAndStillLifesThumbnail13 from "../assests/img/Thumbanil/Interiors-and-Still Lifes/Tools and Cupcakes 1987 oil.webp";
import InteriorsAndStillLifesThumbnail14 from "../assests/img/Thumbanil/Interiors-and-Still Lifes/Chair and Coffee Table with Ashtray and Book 1987 oil.webp";
import InteriorsAndStillLifesThumbnail15 from "../assests/img/Thumbanil/Interiors-and-Still Lifes/Cupcakes and Hammer 1986 oil on prepared museum board.webp";
import InteriorsAndStillLifesThumbnail16 from "../assests/img/Thumbanil/Interiors-and-Still Lifes/Eclipse Series Bottles on a Board and Block 1992 oil.webp";
import InteriorsAndStillLifesThumbnail17 from "../assests/img/Thumbanil/Interiors-and-Still Lifes/Bottles on a Table 1985 oil on prepared museum board.webp";
import InteriorsAndStillLifesThumbnail18 from "../assests/img/Thumbanil/Interiors-and-Still Lifes/Eclipse Series Bottles Prone on a Speaker 1989.webp";
import InteriorsAndStillLifesThumbnail19 from "../assests/img/Thumbanil/Interiors-and-Still Lifes/Rhythmic Eclipse 1983 oil.webp";
import InteriorsAndStillLifesThumbnail20 from "../assests/img/Thumbanil/Interiors-and-Still Lifes/Eclipse Series Bottles on a Speaker II 1988 oil on prepared museum board.webp";
import InteriorsAndStillLifesThumbnail21 from "../assests/img/Thumbanil/Interiors-and-Still Lifes/Plants on a Coffee Table 1982 oil on prepared museum board.webp";
import InteriorsAndStillLifesThumbnail22 from "../assests/img/Thumbanil/Interiors-and-Still Lifes/Fallen Sprayer 1983 oil.webp";
import InteriorsAndStillLifesThumbnail23 from "../assests/img/Thumbanil/Interiors-and-Still Lifes/Sprayers on Parade 1983 oil on canvas.webp";
import InteriorsAndStillLifesThumbnail24 from "../assests/img/Thumbanil/Interiors-and-Still Lifes/The Three Graces 1983 oil.webp";

// Limitation and Death
import LimitationAndDeath1 from "../assests/img/GelleryImg/Limitations-and-Death/Entropy & Limitation Series  The Duchess and Duke of Atlanta, Wheelchair- Bound (left panel of the diptych), 2022, acrylic, oil, metallic enamel & chalk on chalk board.webp";
import LimitationAndDeath2 from "../assests/img/GelleryImg/Limitations-and-Death/Nature Mort Series  Mourning, diptych,  2022, acrylic, oil, metallic enamel & on two wood panels.webp";
import LimitationAndDeath3 from "../assests/img/GelleryImg/Limitations-and-Death/Nature Mort Series  Burial, diptych, 2021-22 , acrylic, oil & metallic enamel on two wood panels.webp";
import LimitationAndDeath4 from "../assests/img/GelleryImg/Limitations-and-Death/Entropy & Limitation Series Who Are You Really.webp";
import LimitationAndDeath5 from "../assests/img/GelleryImg/Limitations-and-Death/Entropy & Limitation Series East Lake Country Memories of the Tate Bacon.webp";
import LimitationAndDeath6 from "../assests/img/GelleryImg/Limitations-and-Death/Entropy & Limitation Series Recollections of Provence Canyon.webp";
import LimitationAndDeath7 from "../assests/img/GelleryImg/Limitations-and-Death/Nature Mort Series  Amphibious Skull, 2022, acrylic & oil on wood panel.webp";
import LimitationAndDeath8 from "../assests/img/GelleryImg/Limitations-and-Death/Nature Mort Series  Dead Immigrant, 2017-18, acrylic, oil gold leaf & cold rolled steel on wood panel.webp";
import LimitationAndDeath9 from "../assests/img/GelleryImg/Limitations-and-Death/Nature Mort Series  Morgue after Bacon, 2017, acrylic, oil, metallic enamel, graphite,  faux granite, stick-on-numbers & plexiglass on wood panel.webp";
import LimitationAndDeath10 from "../assests/img/GelleryImg/Limitations-and-Death/Nature Mort Series Death Meets Flight, 2018, acrylic, oil, gold leaf & wood molding on wood panel.webp";

// Limitation and Death Thumbnail
import LimitationAndDeathThumbnail1 from "../assests/img/Thumbanil/Limitations-and-Death/Entropy & Limitation Series  The Duchess and Duke of Atlanta, Wheelchair- Bound (left panel of the diptych), 2022, acrylic, oil, metallic enamel & chalk on chalk board.webp";
import LimitationAndDeathThumbnail2 from "../assests/img/Thumbanil/Limitations-and-Death/Nature Mort Series  Mourning, diptych,  2022, acrylic, oil, metallic enamel & on two wood panels.webp";
import LimitationAndDeathThumbnail3 from "../assests/img/Thumbanil/Limitations-and-Death/Nature Mort Series  Burial, diptych, 2021-22 , acrylic, oil & metallic enamel on two wood panels.webp";
import LimitationAndDeathThumbnail4 from "../assests/img/Thumbanil/Limitations-and-Death/Entropy & Limitation Series Who Are You Really.webp";
import LimitationAndDeathThumbnail5 from "../assests/img/Thumbanil/Limitations-and-Death/Entropy & Limitation Series East Lake Country Memories of the Tate Bacon.webp";
import LimitationAndDeathThumbnail6 from "../assests/img/Thumbanil/Limitations-and-Death/Entropy & Limitation Series Recollections of Provence Canyon.webp";
import LimitationAndDeathThumbnail7 from "../assests/img/Thumbanil/Limitations-and-Death/Nature Mort Series  Amphibious Skull, 2022, acrylic & oil on wood panel.webp";
import LimitationAndDeathThumbnail8 from "../assests/img/Thumbanil/Limitations-and-Death/Nature Mort Series  Dead Immigrant, 2017-18, acrylic, oil gold leaf & cold rolled steel on wood panel.webp";
import LimitationAndDeathThumbnail9 from "../assests/img/Thumbanil/Limitations-and-Death/Nature Mort Series  Morgue after Bacon, 2017, acrylic, oil, metallic enamel, graphite,  faux granite, stick-on-numbers & plexiglass on wood panel.webp";
import LimitationAndDeathThumbnail10 from "../assests/img/Thumbanil/Limitations-and-Death/Nature Mort Series Death Meets Flight, 2018, acrylic, oil, gold leaf & wood molding on wood panel.webp";

// BATCH 1
export const gallery = [
  {
    img: [gallery1],
    name: "Coastal Places and Things",
    alt: "Coastal Places and Things",
    title: "Coastal Places and Things",
    // className: "leftImg selectedImgBox",
    link: "/gallery/coastal-places-and-things",
  },
  {
    img: [gallery2],
    name: "Nature: Humans, Animals, and Elements",
    alt: "Nature: Humans, Animals, and Elements",
    title: "Nature: Humans, Animals, and Elements",
    // className: "leftImg selectedImgBox",
    link: "/gallery/nature-humans-animals-and-elements",
  },
  {
    img: [gallery3],
    name: "Playgrounds",
    alt: "Playgrounds",
    title: "Coastal Places and Things",
    // className: "RightImg selectedImgBox",
    link: "/gallery/playgrounds",
    className: "gallery3",
  },
  {
    img: [gallery4],
    name: "Interiors and Still Lifes",
    // className: "leftImg selectedImgBox",
    alt: "Interiors and Still Lifes",
    title: "Interiors and Still Lifes",
    link: "/gallery/interiors-and-still-lifes",
  },

  {
    img: [gallery5],
    name: " Limitations and Death",
    alt: " Limitations and Death",
    title: " Limitations and Death.",
    className: " Limitations-and-Death5",
    link: "/gallery/limitation-and-death",
  },
];

export const Coastalplacesandthings = [
  {
    src: CoastalPlacesandThings18,
    srcset: CoastalPlacesandThingsThumbnail18,
    title:
      "Boone Brothers Recreating: Seventh and Final Day on Folly Island, 2024, Acrylic and Oll on Wood Panel, 48”  x 41” ",
    alt: "Boone Brothers Recreating: Seventh and Final Day on Folly Island, 2024, Acrylic and Oll on Wood Panel, 48”  x 41” ",
    // className: "first-img bigimg",
    className: "first-img Coastalplacesandthingsfirstrow",
  },
  {
    src: CoastalPlacesandThings1,
    srcset: CoastalPlacesandThingsThumbnail1,
    title: "Folly Beach, 2024, acrylic & oil on wood panel, 45 ½” x 47 7/8”",
    alt: "Folly Beach, 2024, acrylic & oil on wood panel, 45 ½” x 47 7/8”",
    // className: "first-img bigimg",
    className: "first-img Coastalplacesandthingsfirstrow",
  },
  {
    src: CoastalPlacesandThings2,
    srcset: CoastalPlacesandThingsThumbnail2,
    title: "The Decampment, 2024, acrylic & oil on wood panel, 47” x 40 ½”",
    alt: "The Decampment, 2024, acrylic & oil on wood panel, 47” x 40 ½”",
    // className: "first-img bigimg",
    className: "first-img Coastalplacesandthingsfirstrow",
  },
  {
    src: CoastalPlacesandThings3,
    srcset: CoastalPlacesandThingsThumbnail3,
    title: "Kayaks at Rest, 2024, acrylic & oil on wood panel, 41 ½” x 45 ½”",
    alt: "Kayaks at Rest, 2024, acrylic & oil on wood panel, 41 ½” x 45 ½”",
    className: "first-img bigimg Coastalplacesandthingsfirstrow",
  },
  {
    src: CoastalPlacesandThings4,
    srcset: CoastalPlacesandThingsThumbnail4,
    title:
      "Beach Paraphernalia 3, 2023, acrylic & oil on wood panel, 42 ½” x 40”",
    alt: "Beach Paraphernalia 3, 2023, acrylic & oil on wood panel, 42 ½” x 40”",
    className:
      "first-img bigimg Coastalplacesandthingsfirstrow CoastalPlacesandThings4",
  },
  {
    src: CoastalPlacesandThings5,
    srcset: CoastalPlacesandThingsThumbnail5,
    title: "Birds in Repose, 2024, Acrylic and Oil on Wood panel, 48” x 51”",
    alt: "Birds in Repose, 2024, Acrylic and Oil on Wood panel, 48x51",
    className: "first-img bigimg ",
  },
  {
    src: CoastalPlacesandThings71,
    srcset: CoastalPlacesandThingsThumbnail71,
    title:
      "Beach Paraphernalia 4, 2024, Acrylic and Oil on Wood Panel, 22 3/4” x 30”",
    alt: "Beach Paraphernalia 4, 2024, Acrylic and Oil on Wood Panel, 22 3/4” x 30”",
    className: "first-img bigimg",
  },
  {
    src: CoastalPlacesandThings6,
    srcset: CoastalPlacesandThingsThumbnail6,
    title:
      "Pole, Guy lines, and Sandbag Stake, 2021, acrylic & oil on wood panel, 22” x 21”",
    alt: "Pole, Guy lines, and Sandbag Stake, 2021, acrylic & oil on wood panel, 22” x 21”",
    className: "first-img bigimg ",
  },

  {
    src: CoastalPlacesandThings7,
    srcset: CoastalPlacesandThingsThumbnail7,
    title:
      "Beach Paraphernalia 2, 2021, acrylic & oil on wood panel, 22 ½” x 40”",
    alt: "Beach Paraphernalia 2, 2021, acrylic & oil on wood panel, 22 ½” x 40”",
    className:
      "first-img bigimg Coastalplacesandthingssecondrow CoastalPlacesandThings7",
  },

  {
    src: CoastalPlacesandThings8,
    srcset: CoastalPlacesandThingsThumbnail8,
    title:
      "Beach Paraphernalia 1, 2021, acrylic & oil on wood panel, 22” x 34”",
    alt: "Beach Paraphernalia 1, 2021, acrylic & oil on wood panel, 22” x 34”",
    className:
      "img2 smallimg Coastalplacesandthingsthirdrow CoastalPlacesandThings8",
  },
  {
    src: CoastalPlacesandThings9,
    srcset: CoastalPlacesandThingsThumbnail9,
    title: "Beach Forms 2, 2023, acrylic & oil on wood panel, 8” x 10”",
    alt: "Beach Forms 2, 2023, acrylic & oil on wood panel, 8” x 10”",
    className:
      "2nd-img bigimgsecond Coastalplacesandthingsthirdrow CoastalPlacesandThingsThumbnail9",
  },
  {
    src: CoastalPlacesandThings10,
    srcset: CoastalPlacesandThingsThumbnail10,
    title:
      "Walt and the Seagull, 1996, acrylic and oil on prepared museum board, 18” x 23”",
    alt: "Walt and the Seagull, 1996, acrylic and oil on prepared museum board, 18” x 23”",
    className:
      "2nd-img smallimg Coastalplacesandthingsthirdrow object-position-CoastalPlacesandThings9",
  },
  {
    src: CoastalPlacesandThings11,
    srcset: CoastalPlacesandThingsThumbnail11,
    title: "Beach Shapes 1, 2022, acrylic & oil on wood panel, 24” x 32”",
    alt: "Beach Shapes 1, 2022, acrylic & oil on wood panel, 24” x 32”",
    className: "2nd-img bigimgsecond CoastalPlacesandThings11",
  },
  {
    src: CoastalPlacesandThings12,
    srcset: CoastalPlacesandThingsThumbnail12,
    title: "Beach Shapes 2, 2023, acrylic & oil on wood panel, 8” x 10”",
    alt: "Beach Shapes 2, 2023, acrylic & oil on wood panel, 8” x 10”",
    className: "first-img bigimg CoastalPlacesandThings12",
  },

  {
    src: CoastalPlacesandThings13,
    srcset: CoastalPlacesandThingsThumbnail13,
    title:
      "Lake Michigan Lifeguard Tower, 1997, acrylic and oil on canvas, 79” x 51”",
    alt: "Lake Michigan Lifeguard Tower, 1997, acrylic and oil on canvas, 79” x 51”",
    className: "first-img bigimg",
  },
  {
    src: CoastalPlacesandThings14,
    srcset: CoastalPlacesandThingsThumbnail14,
    title: "Shells in Marsh, 2011, acrylic & oil on wood panel, 24” x 36”",
    alt: "Shells in Marsh, 2011, acrylic & oil on wood panel, 24” x 36”",
    className: "first-img bigimg CoastalPlacesandThings14",
  },
  {
    src: CoastalPlacesandThings15,
    srcset: CoastalPlacesandThingsThumbnail15,
    title:
      "Walt on the Beach, 1996, acrylic and oil on prepared museum board, 13” x 10”",
    alt: "Walt on the Beach, 1996, acrylic and oil on prepared museum board, 13” x 10”",
    className: "first-img bigimg",
  },
  {
    src: CoastalPlacesandThings16,
    srcset: CoastalPlacesandThingsThumbnail16,
    title:
      "Bather on Lake Michigan Beach Wall, 1996, oil on prepared museum board, 10” x 13”",
    alt: "Bather on Lake Michigan Beach Wall, 1996, oil on prepared museum board, 10” x 13”",
    className: "first-img bigimg Coastalplacesandthingssixteen",
  },
  {
    src: CoastalPlacesandThings17,
    srcset: CoastalPlacesandThingsThumbnail17,
    title: "Beach Shapes 3, 2023, acrylic & oil on wood panel, 10” x 8”",
    alt: "Beach Shapes 3, 2023, acrylic & oil on wood panel, 10” x 8”",
    className: "first-img bigimg",
  },
];
export const HumansAndAnimals = [
  {
    src: HumansAndAnimals1,
    srcset: HumansAndAnimalsThumbnail1,
    title:
      "Lost in Reading, 2022-23, acrylic & oil on wood panel, 36 7/8” x 47 ¼” In Permanent Collection of the School of Liberal Arts, GGC, Lawrenceville, GA",
    alt: "Lost in Reading, 2022-23, acrylic & oil on wood panel, 36 7/8” x 47 ¼” In Permanent Collection of the School of Liberal Arts, GGC, Lawrenceville, GA",
    className: "first-img bigimg HumansAndAnimals1",
  },
  {
    src: HumansAndAnimals2,
    srcset: HumansAndAnimalsThumbnail2,
    title:
      "Bear Market, 2014-15, acrylic, oil, newsprint & acrylic sealant on two wood panels, 62” x 48”",
    alt: "Bear Market, 2014-15, acrylic, oil, newsprint & acrylic sealant on two wood panels, 62” x 48”",
    className: "img2 smallimg object-position-HumansAndAnimals2",
  },
  {
    src: HumansAndAnimals3,
    srcset: HumansAndAnimalsThumbnail3,
    title:
      "Meditation on a Pocket, triptych, 2012, acrylic & oil on three wood panels with maple frame, 23 ½” x 63”",
    alt: "Meditation on a Pocket, triptych, 2012, acrylic & oil on three wood panels with maple frame, 23 ½” x 63”",
    className: "first-img bigimg",
  },
  {
    src: HumansAndAnimals4,
    srcset: HumansAndAnimalsThumbnail4,
    title:
      "Waiting, 2012, acrylic, oil & cold wax on two wood panels, 60” x 42”",
    alt: "Waiting, 2012, acrylic, oil & cold wax on two wood panels, 60” x 42”",
    className: "first-img bigimg object-position-HumansAndAnimals4",
  },
  {
    src: HumansAndAnimals5,
    srcset: HumansAndAnimalsThumbnail5,
    title:
      "Worker Series: The Sweeper, Atlanta, 2011, spray paint, acrylic, oil & cold wax on wood panel, 24” x 18”",
    alt: "Worker Series: The Sweeper, Atlanta, 2011, spray paint, acrylic, oil & cold wax on wood panel, 24” x 18”",
    className: "2nd-img bigimgsecond object-position-HumansAndAnimals5",
  },
  {
    src: HumansAndAnimals6,
    srcset: HumansAndAnimalsThumbnail6,
    title:
      "Awaiting Walt I, 1997, acrylic and oil on prepared museum board, 10” x 13”",
    alt: "Awaiting Walt I, 1997, acrylic and oil on prepared museum board, 10” x 13”",
    className: "first-img bigimg object-position-HumansAndAnimals6",
  },
  {
    src: HumansAndAnimals7,
    srcset: HumansAndAnimalsThumbnail7,
    title:
      "Awaiting Walt II, 1997, acrylic and oil on prepared museum board, 10” x 13”",
    alt: "Awaiting Walt II, 1997, acrylic and oil on prepared museum board, 10” x 13”",
    className: "first-img bigimg ",
  },
  {
    src: HumansAndAnimals8,
    srcset: HumansAndAnimalsThumbnail8,
    title:
      "Let Sleeping Dogs Lie, 2013, acrylic, oil & metallic enamel on wood panel, 15” x 17”",
    alt: "Let Sleeping Dogs Lie, 2013, acrylic, oil & metallic enamel on wood panel, 15” x 17”",
    className: "first-img bigimg HumansAndAnimals8",
  },
  {
    src: HumansAndAnimals9,
    srcset: HumansAndAnimalsThumbnail9,
    title: "Blue Canine, 2012, acrylic & oil on wood panel, 36 ½” x 42”",
    alt: "Blue Canine, 2012, acrylic & oil on wood panel, 36 ½” x 42”",
    className: "first-img bigimg HumansAndAnimals9",
  },
  {
    src: HumansAndAnimals10,
    srcset: HumansAndAnimalsThumbnail10,
    title:
      "Encounter, Sniff & Play, 2012, acrylic & oil on wood panel, 48” x 35”",
    alt: "Encounter, Sniff & Play, 2012, acrylic & oil on wood panel, 48” x 35”",
    className: "first-img bigimg",
  },
  {
    src: HumansAndAnimals11,
    srcset: HumansAndAnimalsThumbnail11,
    title:
      "The Departure, 2012, acrylic, oil & metallic enamel on wood panel, 26” x 48”",
    alt: "The Departure, 2012, acrylic, oil & metallic enamel on wood panel, 26” x 48”",
    className: "first-img bigimg object-position-HumansAndAnimals11",
  },
  {
    src: HumansAndAnimals12,
    srcset: HumansAndAnimalsThumbnail12,
    title:
      "At the Park, 2012, enamel, oil & cold wax on wood panel, 31 ½” x 44”",
    alt: "At the Park, 2012, enamel, oil & cold wax on wood panel, 31 ½” x 44”",
    className: "first-img bigimg object-position-HumansAndAnimals12",
  },
  {
    src: HumansAndAnimals13,
    srcset: HumansAndAnimalsThumbnail13,
    title:
      "Things that Fly, 2011, acrylic, oil & cold wax on wood panel, 24” x 30”",
    alt: "Things that Fly, 2011, acrylic, oil & cold wax on wood panel, 24” x 30”",
    className: "first-img bigimg object-position-HumansAndAnimals13",
  },
  {
    src: HumansAndAnimals14,
    srcset: HumansAndAnimalsThumbnail14,
    title:
      "Nature Mort Series: Dead Sea Lion, California, Daytime, 2016, acrylic, oil & cold wax on wood panel, 25” x 35”",
    alt: "Nature Mort Series: Dead Sea Lion, California, Daytime, 2016, acrylic, oil & cold wax on wood panel, 25” x 35”",
    className: "first-img bigimg object-position-HumansAndAnimals14",
  },
  {
    src: HumansAndAnimals15,
    srcset: HumansAndAnimalsThumbnail15,
    title:
      "Nature Mort Series: Dead Sea Lion, California, Twilight, 2016, acrylic & oil on wood panel, 25” x 35”",
    alt: "Nature Mort Series: Dead Sea Lion, California, Twilight, 2016, acrylic & oil on wood panel, 25” x 35”",
    className: "first-img bigimg object-position-HumansAndAnimals15",
  },
  {
    src: HumansAndAnimals16,
    srcset: HumansAndAnimalsThumbnail16,
    title:
      "Nature Mort Series: Dead Sea Lion, California, Nighttime, 2017, acrylic & oil on wood panel, 25” x 35”",
    alt: "Nature Mort Series: Dead Sea Lion, California, Nighttime, 2017, acrylic & oil on wood panel, 25” x 35”",
    className: "first-img bigimg",
  },
  {
    src: HumansAndAnimals17,
    srcset: HumansAndAnimalsThumbnail17,
    title:
      "Nature Mort Series: Dead Goose, 2019, acrylic & oil on museum board, 17 5/8” x 22”",
    alt: "Nature Mort Series: Dead Goose, 2019, acrylic & oil on museum board, 17 5/8” x 22”",
    className: "first-img bigimg object-position-HumansAndAnimals17",
  },
  {
    src: HumansAndAnimals18,
    srcset: HumansAndAnimalsThumbnail18,
    title:
      "Nature Mort Series: Severed Leg, 2017, acrylic & oil on museum board, 17 ¾” x 27 7/8”",
    alt: "Nature Mort Series: Severed Leg, 2017, acrylic & oil on museum board, 17 ¾” x 27 7/8”",
    className: "first-img bigimg object-position-HumansAndAnimals18",
  },
  {
    src: HumansAndAnimals19,
    srcset: HumansAndAnimalsThumbnail19,
    title:
      "Purdue University Mural: Pastoral Eclipse – AM, 1998, acrylic and oil on four conjoined canvases, 144” x 144”",
    alt: "Purdue University Mural: Pastoral Eclipse – AM, 1998, acrylic and oil on four conjoined canvases, 144” x 144”",
    className: "first-img bigimg",
  },
  {
    src: HumansAndAnimals20,
    srcset: HumansAndAnimalsThumbnail20,
    title:
      "Purdue University Mural: Pastoral Eclipse – PM, 1998-99, acrylic and oil on four conjoined canvases, 144” x 144”",
    alt: "Purdue University Mural: Pastoral Eclipse – PM, 1998-99, acrylic and oil on four conjoined canvases, 144” x 144”",
    className: "first-img bigimg",
  },
  {
    src: HumansAndAnimals21,
    srcset: HumansAndAnimalsThumbnail21,
    title:
      "Entropy Series: Rotting Stumps, 2022 , acrylic & oil on wood panel, 24” x 30”",
    alt: "Entropy Series: Rotting Stumps, 2022 , acrylic & oil on wood panel, 24” x 30”",
    className: "first-img bigimg object-position-HumansAndAnimals21",
  },
  {
    src: HumansAndAnimals22,
    srcset: HumansAndAnimalsThumbnail22,
    title:
      "Entropy Series: Double Negative 1, 2016, acrylic & oil on wood panel, 18 7/8” x 24”",
    alt: "Entropy Series: Double Negative 1, 2016, acrylic & oil on wood panel, 18 7/8” x 24”",
    className: "first-img bigimg object-position-HumansAndAnimals22",
  },
  {
    src: HumansAndAnimals23,
    srcset: HumansAndAnimalsThumbnail23,
    title:
      "Entropy Series: Double Negative 2, 2016, acrylic & oil on wood panel, 30” x 20”",
    alt: "Entropy Series: Double Negative 2, 2016, acrylic & oil on wood panel, 30” x 20”",
    className: "first-img bigimg HumansAndAnimals23",
  },
  {
    src: HumansAndAnimals24,
    srcset: HumansAndAnimalsThumbnail24,
    title:
      "Entropy Series: Double Negative 3, 2016, acrylic & oil on museum board, 19” x 28 ¾”",
    alt: "Entropy Series: Double Negative 3, 2016, acrylic & oil on museum board, 19” x 28 ¾”",
    className: "first-img bigimg HumansAndAnimals24",
  },
  {
    src: HumansAndAnimals25,
    srcset: HumansAndAnimalsThumbnail25,
    title: "Appalachian Rocks, 2011, acrylic, & oil on wood panel, 24” x 36”",
    alt: "Appalachian Rocks, 2011, acrylic, & oil on wood panel, 24” x 36”",
    className: "first-img bigimg HumansAndAnimals25",
  },
  {
    src: HumansAndAnimals26,
    srcset: HumansAndAnimalsThumbnail26,
    title: "Atlanta Lilies, 2013, acrylic & oil on wood panel, 35” x 48”",
    alt: "Atlanta Lilies, 2013, acrylic & oil on wood panel, 35” x 48”",
    className: "first-img bigimg object-position-HumansAndAnimals26",
  },
  {
    src: HumansAndAnimals27,
    srcset: HumansAndAnimalsThumbnail27,
    title:
      "Atlanta Builds Series #1, 2023, acrylic & oil on wood panel, 36” x 51”",
    alt: "Atlanta Builds Series #1, 2023, acrylic & oil on wood panel, 36” x 51”",
    className: "first-img bigimg HumansAndAnimals27",
  },
];
export const playgroundData = [
  {
    src: Playgrounds1,
    srcset: PlaygroundsThumbnail1,
    title: "Shrine, 2002, enamel & oil on wood panel, 36” x 30”",
    alt: "Shrine, 2002, enamel & oil on wood panel, 36” x 30”",
    className: "first-img bigimg object-position-Playgrounds1",
  },
  {
    src: Playgrounds2,
    srcset: PlaygroundsThumbnail2,
    title: "Spiral Slide, 2002, enamel & oil on wood panel, 30” x 24”",
    alt: "Spiral Slide, 2002, enamel & oil on wood panel, 30” x 24”",
    className: "img2 smallimg",
  },
  {
    src: Playgrounds3,
    srcset: PlaygroundsThumbnail3,
    title: "Seesaw, 2002, enamel & oil on wood panel, 24” x 48”",
    alt: "Seesaw, 2002, enamel & oil on wood panel, 24” x 48”",
    className: "first-img bigimg object-position-Playgrounds3",
  },
  {
    src: Playgrounds4,
    srcset: PlaygroundsThumbnail4,
    title: "Swing Series #6: Dusk, 2001, enamel & oil on wood panel, 30” x 24”",
    alt: "Swing Series #6: Dusk, 2001, enamel & oil on wood panel, 30” x 24”",
    className: "first-img bigimg object-position-Playgrounds4",
  },
  {
    src: Playgrounds5,
    srcset: PlaygroundsThumbnail5,
    title: "Swing Series #3:  Red Sky, 2001, oil on canvas,  24” x 30”",
    alt: "Swing Series #3:  Red Sky, 2001, oil on canvas,  24” x 30”",
    className: "2nd-img bigimgsecond",
  },
  {
    src: Playgrounds6,
    srcset: PlaygroundsThumbnail6,
    title: "Swing Series #2:  Evening, 2000, oil on two canvases, 70“ x 91”",
    alt: "Swing Series #2:  Evening, 2000, oil on two canvases, 70“ x 91”",
    className: "first-img bigimg object-position-Playgrounds6",
  },
  {
    src: Playgrounds7,
    srcset: PlaygroundsThumbnail7,
    title: "Slide Twilight #3, 2001, oil on two canvases, 81” x 62”",
    alt: "Slide Twilight #3, 2001, oil on two canvases, 81” x 62”",
    className: "first-img bigimg ",
  },
  {
    src: Playgrounds8,
    srcset: PlaygroundsThumbnail8,
    title:
      "Slide Twilight #2, 2001, acrylic, oil & cold wax on wood panel, 13” x 10”",
    alt: "Slide Twilight #2, 2001, acrylic, oil & cold wax on wood panel, 13” x 10”",
    className: "first-img bigimg Playgrounds8",
  },
  {
    src: Playgrounds9,
    srcset: PlaygroundsThumbnail9,
    title: "Swing Series #1, 1997, oil on prepared museum board, 13” x 10”",
    alt: "Swing Series #1, 1997, oil on prepared museum board, 13” x 10”",
    className: "first-img bigimg Playgrounds9",
  },
];
export const StillLifesData = [
  {
    src: InteriorsAndStillLifes1,
    srcset: InteriorsAndStillLifesThumbnail1,
    title: "Green Chair, 1987, oil on canvas, 38” x 46”",
    alt: "Green Chair, 1987, oil on canvas, 38” x 46”",
    className: "first-img bigimg",
  },
  {
    src: InteriorsAndStillLifes2,
    srcset: InteriorsAndStillLifesThumbnail2,
    title: "Violet in Arch, 1992, oil on prepared museum board, 12” x 12”",
    alt: "Violet in Arch, 1992, oil on prepared museum board, 12” x 12”",
    className: "img2 smallimg",
  },
  {
    src: InteriorsAndStillLifes3,
    srcset: InteriorsAndStillLifesThumbnail3,
    title:
      "Eclipse Series: Violet Above on Block, 1992, oil on canvas, 12” x 8”",
    alt: "Eclipse Series: Violet Above on Block, 1992, oil on canvas, 12” x 8”",
    className: "img2 smallimg",
  },
  {
    src: InteriorsAndStillLifes4,
    srcset: InteriorsAndStillLifesThumbnail4,
    title: "Violets on Milkcrates, 1990, oil on canvas, 38” x 46”",
    alt: "Violets on Milkcrates, 1990, oil on canvas, 38” x 46”",
    className: "img2 smallimg object-position-StillLifesData4",
  },
  {
    src: InteriorsAndStillLifes5,
    srcset: InteriorsAndStillLifesThumbnail5,
    title:
      "Eclipse Series: Two African Violets, 1990, oil on canvas, 34” x 56”",
    alt: "Eclipse Series: Two African Violets, 1990, oil on canvas, 34” x 56”",
    className: "img2 smallimg object-position-StillLifesData5",
  },
  {
    src: InteriorsAndStillLifes6,
    srcset: InteriorsAndStillLifesThumbnail6,
    title: "Corner of My Studio at Night, 1987, oil on canvas, 38” x 46”",
    alt: "Corner of My Studio at Night, 1987, oil on canvas, 38” x 46”",
    className: "img2 smallimg",
  },
  {
    src: InteriorsAndStillLifes7,
    srcset: InteriorsAndStillLifesThumbnail7,
    title: "His and Hers, 1988, pastel and colored pencil on paper, 19” x 24”",
    alt: "His and Hers, 1988, pastel and colored pencil on paper, 19” x 24”",
    className: "img2 smallimg object-position-StillLifesData7",
  },
  {
    src: InteriorsAndStillLifes8,
    srcset: InteriorsAndStillLifesThumbnail8,
    title:
      " Telephone Series: Solitude, 1988, oil on prepared museum board, 12” x 12”",
    alt: " Telephone Series: Solitude, 1988, oil on prepared museum board, 12” x 12”",
    className: "img2 smallimg",
  },
  {
    src: InteriorsAndStillLifes9,
    srcset: InteriorsAndStillLifesThumbnail9,
    title:
      "Telephone Series: Intimacy, 1988, oil on prepared museum board, 12” x 12”",
    alt: "Telephone Series: Intimacy, 1988, oil on prepared museum board, 12” x 12”",
    className: "img2 smallimg",
  },
  {
    src: InteriorsAndStillLifes10,
    srcset: InteriorsAndStillLifesThumbnail10,
    title: "Ashtray, 1987, oil on prepared museum board, 12” x 11 ¾”",
    alt: "Ashtray, 1987, oil on prepared museum board, 12” x 11 ¾”",
    className: "img2 smallimg",
  },
  {
    src: InteriorsAndStillLifes11,
    srcset: InteriorsAndStillLifesThumbnail11,
    title:
      "Eclipse Series: Concrete Block, 1998, oil on prepared museum board, 10” x 12 ¾”",
    alt: " Eclipse Series: Concrete Block, 1998, oil on prepared museum board, 10” x 12 ¾”",
    className: "img2 smallimg InteriorsAndStillLifes11",
  },
  {
    src: InteriorsAndStillLifes12,
    srcset: InteriorsAndStillLifesThumbnail12,
    title: "Cupcakes on a Speaker, Rearview, 1986, oil on canvas, 15” x 12 ¼”",
    alt: "Cupcakes on a Speaker, Rearview, 1986, oil on canvas, 15” x 12 ¼”",
    className: "img2 smallimg object-position-StillLifesData12",
  },
  {
    src: InteriorsAndStillLifes13,
    srcset: InteriorsAndStillLifesThumbnail13,
    title: "Tools and Cupcakes, 1987, oil on canvas, 16” x 20”",
    alt: "Tools and Cupcakes, 1987, oil on canvas, 16” x 20”",
    className: "img2 smallimg InteriorsAndStillLifes13",
  },
  {
    src: InteriorsAndStillLifes14,
    srcset: InteriorsAndStillLifesThumbnail14,
    title:
      "Chair and Coffee Table with Ashtray and Book, 1987, oil on canvas, 54” x 72”",
    alt: "Chair and Coffee Table with Ashtray and Book, 1987, oil on canvas, 54” x 72”",
    className: "img2 smallimg InteriorsAndStillLifes14",
  },
  {
    src: InteriorsAndStillLifes15,
    srcset: InteriorsAndStillLifesThumbnail15,
    title:
      "Cupcakes and Hammer, 1986, oil on prepared museum board, 15 ¾” x 20 ¼”",
    alt: "Cupcakes and Hammer, 1986, oil on prepared museum board, 15 ¾” x 20 ¼”",
    className: "img2 smallimg InteriorsAndStillLifes15",
  },
  {
    src: InteriorsAndStillLifes16,
    srcset: InteriorsAndStillLifesThumbnail16,
    title:
      "Eclipse Series: Bottles on a Board and Block, 1992, oil on canvas, 8” x 10”",
    alt: "Eclipse Series: Bottles on a Board and Block, 1992, oil on canvas, 8” x 10”",
    className: "img2 smallimg InteriorsAndStillLifes16",
  },
  {
    src: InteriorsAndStillLifes17,
    srcset: InteriorsAndStillLifesThumbnail17,
    title: "Bottles on a Table, 1985, oil on prepared museum board, 24” x 28”",
    alt: "Bottles on a Table, 1985, oil on prepared museum board, 24” x 28”",
    className: "img2 smallimg object-position-StillLifesData17",
  },
  {
    src: InteriorsAndStillLifes18,
    srcset: InteriorsAndStillLifesThumbnail18,
    title:
      "Eclipse Series: Bottles Prone on a Speaker, 1989, oil on canvas, 52” x 70”",
    alt: "Eclipse Series: Bottles Prone on a Speaker, 1989, oil on canvas, 52” x 70”",
    className: "img2 smallimg InteriorsAndStillLifes18",
  },
  {
    src: InteriorsAndStillLifes19,
    srcset: InteriorsAndStillLifesThumbnail19,
    title: "Rhythmic Eclipse, 1983, oil on canvas, 15” x 38 1/8”",
    alt: "Rhythmic Eclipse, 1983, oil on canvas, 15” x 38 1/8”",
    className: "img2 smallimg object-position-StillLifesData19",
  },
  {
    src: InteriorsAndStillLifes20,
    srcset: InteriorsAndStillLifesThumbnail20,
    title:
      "Eclipse Series: Bottles on a Speaker II, 1988, oil on prepared museum board, 22” x 27”",
    alt: "Eclipse Series: Bottles on a Speaker II, 1988, oil on prepared museum board, 22” x 27”",
    className: "img2 smallimg InteriorsAndStillLifes20",
  },
  {
    src: InteriorsAndStillLifes21,
    srcset: InteriorsAndStillLifesThumbnail21,
    title:
      "Plants on a Coffee Table, 1982, oil on prepared museum board, 13 ½” x 16 5/8”",
    alt: "Plants on a Coffee Table, 1982, oil on prepared museum board, 13 ½” x 16 5/8”",
    className: "img2 smallimg InteriorsAndStillLifes21",
  },
  {
    src: InteriorsAndStillLifes22,
    srcset: InteriorsAndStillLifesThumbnail22,
    title: "Fallen Sprayer, 1983, oil on canvas, 14” x 18”",
    alt: "Fallen Sprayer, 1983, oil on canvas, 14” x 18”",
    className: "img2 smallimg object-position-StillLifesData22",
  },
  {
    src: InteriorsAndStillLifes23,
    srcset: InteriorsAndStillLifesThumbnail23,
    title: "Sprayers on Parade, 1983, oil on canvas, 20” x 24”",
    alt: "Sprayers on Parade, 1983, oil on canvas, 20” x 24”",
    className: "img2 smallimg InteriorsAndStillLifes23",
  },
  {
    src: InteriorsAndStillLifes24,
    srcset: InteriorsAndStillLifesThumbnail24,
    title: "The Three Graces, 1983, oil on canvas, 17” x 20”",
    alt: "The Three Graces, 1983, oil on canvas, 17” x 20”",
    className: "img2 smallimg InteriorsAndStillLifes24",
  },
];
export const Entropylimitationanddeath = [
  {
    src: LimitationAndDeath1,
    srcset: LimitationAndDeathThumbnail1,
    title:
      "The Duchess and Duke of Atlanta, Wheelchair- Bound (left panel of the diptych), 2022, acrylic, oil, metallic enamel & chalk on chalk board, 39” x 36”",
    alt: "The Duchess and Duke of Atlanta, Wheelchair- Bound (left panel of the diptych), 2022, acrylic, oil, metallic enamel & chalk on chalk board, 39” x 36”",
    // className: "2nd-img bigimgsecond",
    className: "2nd-img EntropylimitationanddeathFirstRow",
  },
  {
    src: LimitationAndDeath2,
    srcset: LimitationAndDeathThumbnail2,
    title:
      "Mourning, diptych, 2022, acrylic, oil, metallic enamel on two wood panels, 48 ¾” x 37”",
    alt: "Mourning, diptych, 2022, acrylic, oil, metallic enamel on two wood panels, 48 ¾” x 37”",
    // className: "first-img bigimg",
    className: "2nd-img EntropylimitationanddeathFirstRow",
  },
  {
    src: LimitationAndDeath3,
    srcset: LimitationAndDeathThumbnail3,
    title:
      "Burial, diptych, 2021-22, acrylic, oil & metallic enamel on two wood panels, 72 ½” x 48”",
    alt: "Burial, diptych, 2021-22, acrylic, oil & metallic enamel on two wood panels, 72 ½” x 48”",
    // className: "2nd-img smallimg",
    className: "2nd-img EntropylimitationanddeathFirstRow",
  },
  {
    src: LimitationAndDeath4,
    srcset: LimitationAndDeathThumbnail4,
    title:
      "Are You Really, What Are We Gonna Do? Can you?, diptych, 2021, acrylic, oil, metallic enamel, colored pencil, & gold leaf on two wood panels, 39 ¾” x 47 ¾”",
    alt: "Are You Really, What Are We Gonna Do? Can you?, diptych, 2021, acrylic, oil, metallic enamel, colored pencil, & gold leaf on two wood panels, 39 ¾” x 47 ¾”",
    // className: "first-img bigimg",
    className: "first-img Entropy-limitation-and-death-Thumbnail4",
  },
  {
    src: LimitationAndDeath5,
    srcset: LimitationAndDeathThumbnail5,
    title:
      "East Lake Country Memories of the Tate Bacon, triptych, 2020, acrylic & oil on cold rolled steel panel/acrylic, oil, & gold leaf on wood panel/ acrylic, oil, & enamel on aluminum panel, 21 ½” x 50 ½”",
    alt: "East Lake Country Memories of the Tate Bacon, triptych, 2020, acrylic & oil on cold rolled steel panel/acrylic, oil, & gold leaf on wood panel/ acrylic, oil, & enamel on aluminum panel, 21 ½” x 50 ½”",
    // className: "2nd-img bigimgsecond",
    className: "first-img EntropylimitationanddeathSecondRow remove-img-height",
  },
  {
    src: LimitationAndDeath6,
    srcset: LimitationAndDeathThumbnail6,
    title:
      "Recollections of Provence Canyon (Georgia) from Hyde Park, triptych, 2019, acrylic, oil, newsprint & acrylic sealant on wood panel/aluminum panel/acrylic, oil, & gold leaf on wood panel, 21 ½” x 53”",
    alt: "Recollections of Provence Canyon (Georgia) from Hyde Park, triptych, 2019, acrylic, oil, newsprint & acrylic sealant on wood panel/aluminum panel/acrylic, oil, & gold leaf on wood panel, 21 ½” x 53”",
    // className: "first-img bigimg",
    className:
      "first-img EntropylimitationanddeathSecondRow remove-img-height Entropy-limitation-and-death-Thumbnail6",
  },
  {
    src: LimitationAndDeath7,
    srcset: LimitationAndDeathThumbnail7,
    title: "Amphibious Skull, 2022, acrylic & oil on wood panel, 31 ½” x 36”",
    alt: "Amphibious Skull, 2022, acrylic & oil on wood panel, 31 ½” x 36”",
    // className: "first-img bigimg",
    className:
      "first-img EntropylimitationanddeathThirdRow Entropy-limitation-and-death-Thumbnail7",
  },
  {
    src: LimitationAndDeath8,
    srcset: LimitationAndDeathThumbnail8,
    title:
      "Dead Immigrant, 2017-18, acrylic, oil gold leaf & cold rolled steel on wood panel, 35” x 75 1/2”",
    alt: "Dead Immigrant, 2017-18, acrylic, oil gold leaf & cold rolled steel on wood panel, 35” x 75 1/2”",
    // className: "2nd-img bigimgsecond",
    className: "first-img Entropy-limitation-and-death-Thumbnail8",
  },
  {
    src: LimitationAndDeath9,
    srcset: LimitationAndDeathThumbnail9,
    title:
      "Morgue after Bacon, 2017, acrylic, oil, metallic enamel, graphite, faux granite, duct tape, stick-on-numbers & plexiglass on wood panel, 35 5/8” x 77”",
    alt: "Morgue after Bacon, 2017, acrylic, oil, metallic enamel, graphite, faux granite, duct tape, stick-on-numbers & plexiglass on wood panel, 35 5/8” x 77”",
    // className: "2nd-img smallimg",
    className: "first-img Entropy-limitation-and-death-Thumbnail9",
  },
  {
    src: LimitationAndDeath10,
    srcset: LimitationAndDeathThumbnail10,
    title:
      "Nature Mort Series: Death Meets Flight, 2018, acrylic, oil, gold leaf & wood molding on wood panel, 24” x 18”",
    alt: "Nature Mort Series: Death Meets Flight, 2018, acrylic, oil, gold leaf & wood molding on wood panel, 24” x 18”",
    // className: "img2 smallimg",
    className: "first-img EntropylimitationanddeathFourthRow",
  },
];
